$map-property-popup__padding-horizontal: 10px;
$map-property-popup__padding-vertical: 8px;
$map-property-popup__padding-vertical-internal: 6px;

.map-property-popup {
  &__header {
    width: 100%;
    background: getColor(purple, base);
    color: #ffffff;
    padding-left: $map-property-popup__padding-horizontal;
    padding-right: $map-property-popup__padding-horizontal;
    padding-top: $map-property-popup__padding-vertical;
    padding-bottom: $map-property-popup__padding-vertical;
  }

  &__info {
  }

  &__info-header {
    //font-family: $secondary-font-regular;
    //font-weight: 600;
    padding-left: $map-property-popup__padding-horizontal;
    padding-right: $map-property-popup__padding-horizontal;
    margin-top: $map-property-popup__padding-vertical-internal;
  }

  &__info-content {
    padding-left: $map-property-popup__padding-horizontal;
    padding-right: $map-property-popup__padding-horizontal;
    margin-bottom: $map-property-popup__padding-vertical-internal;
  }
}
