@font-face {
  font-family: "FiraSans-Light";
  src: url("../fonts/FiraSans/FiraSans-Light.ttf");
}

@font-face {
  font-family: "FiraSans-Regular";
  src: url("../fonts/FiraSans/FiraSans-Regular.ttf");
}

@font-face {
  font-family: "FiraSans-Bold";
  src: url("../fonts/FiraSans/FiraSans-Bold.ttf");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("../fonts/Gilroy/Gilroy-Regular.otf");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../fonts/Gilroy/Gilroy-Bold.otf");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("../fonts/Gilroy/Gilroy-SemiBold.otf");
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("../fonts/Gilroy/Gilroy-ExtraBold.otf");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy-Bold", bold;
}

body,
p,
div {
  font-family: "FiraSans-Light";
}

#root {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: "Gilroy-Bold", bold;
  }
  
  .btn,
  .pill,
  .card-header {
    font-family: "Gilroy-Bold", bold;
  }
  
  .wi {
    font-family: weathericons !important;
  }
  
  .description {
    font-family: "Gilroy-Regular", sans-serif;
  }
  
  .detail-section-header {
    color: getColor(primary-blue, base);
    font-family: "FiraSans-Bold";
  }
  
  .detail-header {
    color: getColor(primary-blue, lightest);
    font-family: "FiraSans-Bold";
  }
  
  .detail-content {
    font-family: "FiraSans-Regular";
    color: getColor(primary-blue, base);
  }
}
