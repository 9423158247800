.reportingContent {
  color: getColor(primary-blue, base);

  .u-gilroy-regular {
    font-family: Gilroy-Regular;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    display: flex;
    flex-direction: column;
  }

  &__card {
    border: 0.5px solid rgba(19, 50, 86, 0.05);
    box-sizing: border-box;
    box-shadow: 0px 2px 3px rgba(19, 50, 86, 0.1);
    padding: 1rem;
  }

  &__content {
    font-family: FiraSans-Light;
    font-style: normal;
    font-size: 15px;
    line-height: 150%;
  }

  &__header {
    text-align: justify;
    padding: 0.5rem 0;
    justify-content: space-between;
  }

  &__title {
    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 18px;
    letter-spacing: 0.13em;
    display: inline-block;
    white-space: pre-line;
  }

  &__secondaryTitle {
    font-family: FiraSans-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.025em;
    color: getColor(primary-blue, lightest);
    display: inline-block;
  }
}

.calloutCard {
  border: none;
  box-shadow: none;
  margin-bottom: 0;
  border-radius: 0;
  word-wrap: normal;

  @media screen and (max-width: 992px) {
    display: flex;
  }

  &__subtitle {
    font-family: FiraSans-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: getColor(primary-blue, lightest);
    text-align: center;
  }
}

.filterControl {
  &--noHover {
    &:hover {
      background-color: transparent !important;
    }
    &:active {
      background-color: transparent !important;
    }
  }

  &__dropdown {
    .dropdown-item {
      padding: 8px;
      &:hover {
        background-color: #e2fbf9;
      }
      &:active {
        background-color: #e2fbf9;
      }
    }

    &__autoComplete {
      .fa-search {
        color: #c2ccd6;
        font-weight: bold;
        line-height: 40px;
      }
      .form-control {
        border: 0;
        font-size: larger;

        &:focus {
          border: transparent;
        }
      }
      .dropdown-divider {
        background-color: #00d0c3;
        height: 4px;
        margin: 0;
      }
      .form-control:focus {
        box-shadow: none;
      }
    }

    &__item {
      color: #063259;

      &__label {
        margin-left: 20px;
      }

      i {
        color: #37d0c3;
        font-weight: bold;
        font-size: small;
        line-height: 22px;
        width: 0;
      }
    }
  }

  &__filterButton {
    color: #37d0c3;
    font-family: FiraSans-Regular;
    font-size: smaller;
    margin: 0.5rem;

    .fa-times {
      color: white;
      font-size: larger;
      position: relative;
      bottom: -1px;
    }
  }

  .dropdown-menu {
    border-radius: 0;
  }

  .dropdown-toggle.nav-link {
    font-family: Gilroy-Bold;
    color: #fff;
  }
  .disabled {
    .dropdown-toggle.nav-link {
      color: getColor(primary-blue, lighter);
    }
  }

  // Calendar overrides
  .CalendarMonth_caption {
    color: #0f2845 !important;
  }
  .CalendarDay__selected_span {
    background: #e2fbf9 !important;
    color: #0f2845 !important;
  }
  .CalendarDay__selected {
    background: #37d0c3 !important;
  }
}

.locationList {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.025em;

  &__header {
    color: getColor(primary-blue, lightest);
  }

  &__body {
    color: getColor(primary-blue, base);
    font-size: 13px;
  }

  .basicTable {
    &__container {
      overflow-x: auto;
    }

    &__noResults {
      background-color: $color-vwhite;
      margin-top: -1rem;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      font-size: 2.5rem;
      line-height: 1.5;
    }
  }

  table {
    background-color: $color-vwhite;
    border: none;
    box-sizing: border-box;

    th,
    td {
      border-bottom: 2.5px solid tint(getColor(primary-blue, base), 95);
      border-top: none;
      border-left: none;
      border-right: none;
      .col {
        justify-content: left;
      }
    }

    th:not(:first-child),
    td:not(:first-child) {
      text-align: center;
      .col {
        justify-content: center;
      }
    }

    td {
      border-bottom-width: 0.25px;
      vertical-align: middle;
      padding: 1em;
    }
  }

  .fa-male {
    color: getColor(purple, base);
  }

  .fa-female,
  .wi {
    color: getColor(turquoise, base);
  }

  .btn-primary {
    border-radius: 0;
    color: $color-vwhite;
    background-color: getColor(purple, base);
    border: none;
  }

  div,
  span {
    font-family: FiraSans-Regular;
  }
}
