//Sidebar

.sidebar {
  position: relative;
  width: $sidebar-width;
  background-color: getColor(primary-blue, base);
  transition: width 0.2s;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
  .card-header {
    //override bootstrap's card style
    background-color: transparent;
    border-bottom: 0;
  }
  .sidebar__main-content {
    flex-grow: 1;
    overflow-y: auto;
  }
  .sidebar__bottom-toggle {
    padding: 0.8em;
    background-color: getColor(primary-blue, dark);
    color: #fff;
  }
  &--light {
    background-color: #fff;
    .sidebar__item {
      &:hover {
        background-color: getColor(turquoise, lightest);
      }
    }
  }
}

.sidebar__item {
  font-size: 14px;
  padding: 1.28em 1.5em;
  color: #fff;
  width: 100%;
  align-items: center;
  font-family: "Gilroy-Bold", bold !important;
  display: flex;
  flex-flow: row;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: getColor(orange, base);
  }
  .fa {
    font-size: 1.5em;
    margin-right: 4px;
    margin-bottom: 2px;
  }
  &:visited {
    color: #e2e2e2;
  }
  &__selected {
    font-family: "Gilroy-ExtraBold";
  }
}

.sidebar--extra-expanded-height.sidebar--expanded {
  padding-top: 0;
}

@media (max-width: $mq-small) {
  .sidebar {
    margin-top: 4rem;
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    transition: left 0.2s;
    &__item {
      flex-flow: row;
    }
    &__main-content {
      width: 100%;
    }
    &--expanded {
      left: 0;
      .fa {
        margin-right: 5px;
        margin-bottom: 0;
      }
    }
  }
}
