.logo {
  background-size: contain;
  background-repeat: no-repeat;
  &--vi-dark {
    background-image: url(../images/VI-logo-dark.png);
  }
  &--vi-light {
    background-image: url(../images/VI-logo-light.png);
  }
  &--vc-dark {
    background-image: url(../images/VC-logo-dark.png);
  }
  &--vc-light {
    background-image: url(../images/VC-logo-light.png);
  }
  &--sm {
    width: 6em;
    height: 1.5em;
  }
  &--md {
    width: 10em;
    height: 2em;
  }
}
