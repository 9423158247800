.pill {
  font-size: 10px;
  padding: 3px 11px 2px;
  background-color: #ff1d66;
  margin: 0 auto;
  border-radius: 2em;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}

.pill--green {
  background-color: #4cc751;
}

.pill--yellow {
  background-color: #f39d00;
}

.pill--blue {
  background-color: #4157d2;
}

.pill--red {
  background-color: #e54a55;
}

.pill--orange {
  background-color: #f39d00;
}

.pill--disabled {
  background-color: #b5b5b5 !important;
  pointer-events: visible !important;
}
