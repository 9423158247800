.page-tabs {
  text-align: center;
  background-color: $color-vblack;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.page-tabs__tab {
  background-color: lighten($color-vblack, 10%);
  color: $color-vgrey;
  padding: 0.5em;
  font-size: 20px;
  transition: 0.5s ease;
  cursor: pointer;
}

.page-tabs__tab:hover {
  background-color: getColor(orange, base);
  color: $color-vwhite;
}

.page-tabs__tab--active {
  background-color: getColor(orange, base);
  color: $color-vwhite;
}
