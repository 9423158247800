.basicTable {
  &__tableHeader {
    max-width: 8rem;
  }

  &__columnHeader {
    align-items: center;
    padding: 0.5rem;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__selected {
    background-color: getColor(turquoise, lighter);
  }
  &__noResults {
    padding: 0.5rem;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.2;
  }

  &__icon-size {
    &.fa {
      font-size: 2em;
    }
    &.wi {
      font-size: 1.5em;
    }
  }
}
