.proposals {
  .campaignProposalsSelector {
    margin: -0.5em;

    .btn-light {
      border: $color-vgrey solid 1px;
    }

    &__selectButton {
      display: flex;
      margin: 0.5em;
      width: 12em;
      justify-content: center;

      &--unsaved {
        min-width: 0;
        margin-left: 0.25em;
      }
    }
  }

  .campaignProposalContainer {
    &__outerText {
      margin: 1.5em;
      text-transform: uppercase;
    }
  }

  .marketBreakdownTable {
    &__headerSection {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }

    &__filterSection {
      display: flex;
      justify-content: flex-end;
    }

    &__toggleIcon {
      color: getColor(purple, base);
      font-size: 2em;
      cursor: pointer;
    }

    &__title {
      font-family: "Gilroy-Bold";
      font-size: 20px;
      color: getColor(primary-blue, base);
    }

    &__header {
      color: getColor(primary-blue, lighter);
    }

    &__body {
      tr:nth-child(even) {
        background: #f8f9fa;
      }
      font-family: "FiraSans-Bold";
      line-height: 3em;
      font-weight: bold;
      color: getColor(primary-blue, base);
    }

    &__footer {
      font-family: "FiraSans-Bold";
      line-height: 3em;
      background-color: getColor(turquoise, lightest);
      font-weight: bold;
      color: getColor(primary-blue, base);
    }

    .basicTable {
      &__container {
        overflow-x: auto;
      }
    }

    table {
      background-color: $color-vwhite;
      border: none;

      th:not(:first-child),
      td:not(:first-child) {
        text-align: center;
        .col {
          justify-content: center;
        }
      }

      th,
      td {
        border-bottom: 1px solid tint(getColor(primary-blue, lighter), 80);
        border-top: none;
        border-left: none;
        border-right: none;
      }
    }
  }

  .propertySelectionSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 0 0.5em;

    &__header {
      font-family: "Gilroy-Bold";
      font-size: 1.2em;
      color: getColor(primary-blue, base);
    }
  }

  .proposalSection {
    &__header {
      display: flex;
      flex-wrap: wrap;
      background-color: getColor(primary-blue);
      padding: 0.5em 2em;
      align-items: center;
      justify-content: space-between;
    }

    &__body {
      padding: 1em 2em;
      hr {
        margin: 2em 0;
      }
    }
  }

  .proposalHeader {
    &__title {
      max-width: 100%;
      flex: 0 0 auto;
      margin: 0.5em 0.5em 0.5em 0;
      font-family: "Gilroy-Bold";
      color: $color-vwhite;
      font-size: 1.2em;
    }

    &__actions {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: -0.5em;
      flex: 0 0 auto;
    }

    .actions__button {
      flex: 0 0 auto;
      margin: 0.5em;
    }
  }

  .flexForm {
    display: flex;
    flex-wrap: wrap;
    margin: -0.25em -0.5em;

    &__group {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
      margin: 0;
    }

    &__field {
      flex: 1 1 10em;
      margin: 0.25em 0.5em;

      &--fixedSm {
        flex: 0 1 5em;
        margin: 0.25em 0.5em;
      }

      &--sm {
        flex: 1 1 5em;
        margin: 0.25em 0.5em;
      }

      &--lg {
        flex: 1 1 15em;
        margin: 0.25em 0.5em;
      }

      &--xl {
        flex: 1 1 20em;
        margin: 0.25em 0.5em;
      }
    }
  }

  .newAdvertiserContainer {
    border: $color-vgrey solid 1px;
    margin: 1em 0;

    &__header {
      display: flex;
      justify-content: space-between;
    }
  }

  .newAdvertiserForm {
    margin: 0.5em;
  }

  .summarySection {
    display: flex;
    justify-content: space-between;
    margin: 3em -2em;
    color: getColor(primary-blue);

    &__main {
      margin: 0 2em;
      display: flex;
      flex-direction: column;
    }

    &__title {
      font-family: "Gilroy-Bold";
      font-size: 1.5em;
    }

    &__list {
      margin: 0.5em -1em;
      display: flex;
      flex-wrap: wrap;
    }

    &__callout {
      margin: 0 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.2em 2em;
      flex-shrink: 0;
      gap: 1.5em;
      background-color: getColor(turquoise, lightest);
    }

    .summaryCallout {
      &__label {
        font-family: "Gilroy-Bold";
        font-size: 1.4em;
        font-weight: bold;
        line-height: 1em;
      }

      &__value {
        line-height: 1.4em;
        font-size: 2.5em;
        text-align: center;
      }

      &__info {
        color: getColor(turquoise);
        font-family: "Gilroy-Bold";
        font-weight: bold;
        text-align: center;
      }
    }

    .summaryItem {
      display: flex;
      flex-wrap: nowrap;
      margin: 0.5em 1em;

      &__label {
        margin-right: 0.25em;
      }

      &__value {
        font-family: "Gilroy-Bold";
        font-weight: bold;
      }
    }
  }
}
