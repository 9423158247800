.u-color-white {
  color: $color-vwhite;
}

.u-color-red {
  color: red;
}

.u-background-color-white {
  background-color: $color-vwhite !important;
}

.u-color-brand-green {
  color: $color-green-brand;
}

.u-color-purple {
  color: getColor(purple, base) !important;
}

.u-color-primary-blue {
  color: getColor(primary-blue, base) !important;
}

.u-color-orange-lighter {
  color: $color-vorange-lighter;
}

.u-transparent-black {
  background-color: rgba(0, 0, 0, 0.76);
}

.u-transparent-black-lighter {
  background-color: rgba(0, 0, 0, 0.55);
}

.u-background-color-light-blue {
  background-color: #ccd9ff;
}

//Object Colors
.u-color-big-notice {
  background-color: $color-blue-light;
}

.u-color-side-notice {
  background-color: $color-green-light;
}

.u-color-user-account {
  background-color: $color-vorange-lighter;
}
