@import "colors.scss";
@import "page_tabs.scss";
@import "mouse_actions.scss";
@import "padding.scss";
@import "text.scss";
@import "fonts.scss";

.u-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-word-break {
  // word-break and overflow-wrap produce the same result but have different compatibility
  word-break: break-word;
  overflow-wrap: anywhere;
}

.error-border {
  padding: 1em;
  border: 1px solid red !important;
}
