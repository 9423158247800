// Keep a reference to the parent selector without nested structure, for use with sibling selectors and the like:
// https://stackoverflow.com/questions/71772535/using-the-css-sibling-selector-along-with-the-scss-parent-selector-withi
$toggleSlider: ".toggleSlider";
#{$toggleSlider} {
  --height: 2.655em;
  --width: 4.688em;
  --track-padding: 0.31em;
  --track-length: calc(var(--height) - var(--track-padding) * 2);
  --height-small: 1.875em;
  --width-small: 3.125em;
  --track-padding-small: 0.155em;
  --track-length-small: calc(var(--height-small) - var(--track-padding-small) * 4);

  display: flex;
  align-items: center;

  &__label {
    margin-right: 0.5em;
    font-weight: bold;
  }

  &__track {
    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);

    &.sm {
      width: var(--width-small);
      height: var(--height-small);
    }

    input {
      display: none;
    }
  }

  &__switch {
    border-radius: var(--height);
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &.sm {
      border-radius: var(--height-small);
    }
  }

  &__switch:before {
    border-radius: 50%;
    position: absolute;
    content: "";
    height: calc(var(--height) - var(--track-padding) * 2);
    width: calc(var(--height) - var(--track-padding) * 2);
    left: var(--track-padding);
    bottom: var(--track-padding);
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &__switch.sm:before {
    height: calc(var(--height-small) - var(--track-padding-small) * 2);
    width: calc(var(--height-small) - var(--track-padding-small) * 2);
    left: var(--track-padding-small);
    bottom: var(--track-padding-small);
  }

  input:checked + #{$toggleSlider}__switch {
    &.primary {
      background-color: map-get($theme-colors, "primary");
    }
    &.secondary {
      background-color: map-get($theme-colors, "secondary");
    }
    &.success {
      background-color: map-get($theme-colors, "success")
    }
    &.danger {
      background-color: map-get($theme-colors, "danger");
    }
    &.warning {
      background-color: map-get($theme-colors, "warning");
    }
    &.info {
      background-color: map-get($theme-colors, "info");
    }
    &.light {
      background-color: map-get($theme-colors, "light");
    }
    &.dark {
      background-color: map-get($theme-colors, "dark");
    }
  }

  input:checked + #{$toggleSlider}__switch:before {
    -webkit-transform: translateX(var(--track-length));
    -ms-transform: translateX(var(--track-length));
    transform: translateX(var(--track-length));
  }

  input:checked + #{$toggleSlider}__switch.sm:before {
    -webkit-transform: translateX(var(--track-length-small));
    -ms-transform: translateX(var(--track-length-small));
    transform: translateX(var(--track-length-small));
  }
}
