.screen-preview {
  position: relative;
  overflow: hidden;

  &__background-image {
    width: 62.5%;
    height: 66.5%;
    position: absolute;
    left: 31.75%;
    top: 9.3%;
  }

  &__notice-content {
    width: 50%;
    height: 58%;
    position: absolute;
    left: 38.25%;
    top: 14.75%;
    word-wrap: break-word;
    padding: 22px 16px 1em;
    background-color: rgba(0, 0, 0, 0.76);

    &__title {
      line-height: 13px;
      text-align: center;
      font-size: 13px;
      color: #fff;
    }

    &__description {
      font-size: 9.3px;
      color: #fff;
      white-space: pre-wrap;
      line-height: 11px;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}


