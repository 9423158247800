.main-form {
  &__container {
    padding: 1em 3em;
    background-color: #fff;
  }
}

.form-section {
  &__title {
    font-weight: bolder;
  }
}
