.BigNoticeFormContainer {
  display: grid;
  height: 86vh;
  grid-template-columns: repeat(2, 1fr);

  .mainTable {
    font-family: "Gilroy-Bold";
  }

  .displaySettingsSection {
    background-color: #fff;
    padding: 3em;
    overflow-x: hidden;

    &__content {
      resize: none;
      height: 25em;
    }
  }

  .screenPreviewSection {
    padding: 3em;
    display: flex;
    flex-flow: column;
    align-items: center;

    .publishedCheckbox {
      /* Double-sized Checkboxes */
      -ms-transform: scale(1.5); /* IE */
      -moz-transform: scale(1.5); /* FF */
      -webkit-transform: scale(1.5); /* Safari and Chrome */
      -o-transform: scale(1.5); /* Opera */
      transform: scale(1.5);
      font-family: "Gilroy-Bold";
    }
    .screenPreviewContainer {
      height: 309px;
      width: 550px;
      box-shadow: 0 0.5rem 1rem #00000026;

      //------START OF CSS BASED OFF ELEV8-ANYWHERE-FRONTEND
      .bigNoticeContent {
        height: 100%;
        width: 100%;
        background-color: #fff;

        .titleContainerForWebkitLineClampPadding {
          margin: 0;
          padding: 15px;
          background-color: #f04d28;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .title {
          font-family: "Gilroy-Bold";
          margin: 0;
          padding: 0;
          font-size: 15px;
          color: #fff;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .content {
          margin: 0;
          padding: 14px;
          white-space: pre-wrap;
          font-size: 11px;
          line-height: 13.7px;
          color: #133256;
          font-family: "FiraSans-Regular";
        }

        .image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
        //------ END OF CSS FROM FROM ELEV8-ANYWHERE-FRONTEND

        &__missingImage {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          color: getColor(primary-blue, lightest);
          .fa-image {
            font-size: 8em;
          }
        }
      }
    }
  }
}

@media (max-width: $mq-medium) {
  .BigNoticeFormContainer {
    display: grid;
    grid-template: none;

    .screenPreviewSection {
      padding: 1em;
      .screenPreviewContainer {
        height: 281px;
        width: 430px;
        background-color: #fff;
        overflow: hidden;
        background-size: cover;
        box-shadow: 0 0.5rem 1rem #00000026;
        .bigNoticeContent {
          .titleContainerForWebkitLineClampPadding {
            padding: 12px;
          }
          .title {
            font-size: 14px;
          }
          .content {
            font-size: 8.8px;
            line-height: 12px;
          }
        }
      }
    }
  }
}
