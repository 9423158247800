.non-vc-property-content {
  &__iframe {
    width: 100%;
    max-height: 50em;
    aspect-ratio: 16 / 9;
  }
  &__title {
    text-align: center;
    padding: 0.5em;
  }
}
