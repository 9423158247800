.screen_notice {
  background: $color-vwhite;
  border: 3px solid $color-vgreen;
  border-radius: 1.0em;
  color: $color-vblack;
  text-align: left;

  &__inline_icon {
    width: initial;
    height: 25px;
    padding-right: 0.5em;
  }
}

