.gallery-picker {
  height: 19em;
  width: 100%;
  overflow: auto;
  background-color: #f8f8f9;
  border-style: solid;
  border-color: #e4e4e4;
  padding: 0.5em 0;
  box-shadow: 0 0.5rem 1rem #00000026;

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    color: #8999ab;
    .fa {
      font-size: 5em;
    }
  }

  &__image {
    margin: 0.5em;
    width: 120px;
    height: auto;
    position: relative;

    &--selected {
      border-style: solid;
      border-width: 0.2em;
      border-radius: 6px;
      border-color: #a6ec54;

      transition: all 0.2s;
      -webkit-transition: width 2s;
    }
  }
}
