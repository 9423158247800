.campaignForm {
  padding: 1em 3em;
  background-color: #fff;

  &__sectionHeader {
    display: flex;
    justify-content: space-between;
    font-weight: bolder;
    padding-top: 1.5rem;
    align-items: center;
  }

  &__table {
    border: 1px solid $color-vgrey;
    border-radius: 4px;
    margin-top: 1rem;
    max-height: 400px;
    overflow-y: auto;
  }

  &__tablePlaceholder {
    border: 1px solid $color-vgrey;
    border-radius: 4px;
    margin-top: 1rem;
    font-style: italic;
    font-size: 18px;
  }

  .form-group {
    margin-bottom: 0;
  }
}
