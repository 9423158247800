@import "utilities/color_variables.scss";
@import "utilities/media_query_variables.scss";
@import "utilities/layout_variables.scss";
@import "utilities/utilities.scss";
@import "vendor/vendor.scss";
@import "components/components.scss";

@media (max-width: $mq-small) {
  .btn-outline-primary:hover {
    color: #007bff;
    background-color: #fff;
    border-color: #007bff;
  }
}
//will probably revisit this again when we update our data table
.noscroll-table-wrapper .row.header .cell {
  font-family: "Gilroy-Bold", bold;
}

.react-datepicker-popper {
  z-index: 2;
}

.tool-tip {
  &__container {
    width: 20em;
  }
  &__header {
    font-size: 1em;
    font-weight: bold;
  }
  &__description {
    p {
      font-size: 7px !important;
    }
  }
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

.h-10 {
  // this is a hack -> https://getbootstrap.com/docs/4.0/utilities/sizing/
  height: 10%;
}

.h-90 {
  // this is a hack -> https://getbootstrap.com/docs/4.0/utilities/sizing/
  height: 90%;
}

.duration-text {
  font-size: 13px;
  margin-top: 3px;
  color: #5a5a5a;
}

.app {
  height: 100%;
}

body {
  height: 100vh;
}

.page-content.d-flex.align-items-stretch {
  flex: 1 1 auto;
}

body {
  background-color: #f0f4f6;
}

label {
  margin-bottom: 0rem;
  font-size: 0.8em;

  &.purple {
    color: #2438a7;
  }
}

.view-form.property {
  .description-box {
    h3 {
      color: #71237e;
    }
  }
}

p.link {
  color: #0093de;
  font-weight: bolder;
  cursor: pointer;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #fff !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.proximity-rodal {
  color: #000;
  .rodal-content {
    display: flex;
    align-items: center;
  }
  input[type="radio"] {
    width: 3em;
  }

  input {
    height: 3em;
    width: 6em;
    padding: 7px;
    margin-left: 1em;
  }

  hr {
    margin: 0 0 8px !important;
  }
  b {
    padding-right: 5px;
  }
  .unit {
    background-color: #e6e6e6;
    border: none;
    color: black;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 2px;
    color: #949494;
    &.highlight {
      background-color: #3f51b5;
      color: #fff;
    }
  }
  .modal-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      padding: 1em;
      border-radius: 2px;
      border-style: solid;
      border-color: #a79f9f;
      border-width: 1px;
      text-align: center;
      margin-right: 1em;
      width: 10em;
      cursor: pointer;
      &.save {
        color: #fff;
        border-color: #4caf50 !important;
        background-color: #4caf50;
      }
      &.cancel {
        color: #777777;
      }
    }
  }
}

.alert {
  margin-top: 1em;
}

.flex-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.full-width {
  width: 100%;
}

.main-dropzone-container {
  height: 14.5em;
  margin-top: 1em;
  margin-right: 2em;
  &__description {
    font-size: 13px;
    margin-bottom: 6px;
  }
  &__title {
    font-weight: bold;
  }

  .drop-zone-container {
    width: 100%;
    height: 80%;

    .drop-zone-preview {
      width: 100%;
      height: 100%;

      .dropzone-button {
        width: 100%;
        background-color: #fbfdff;
        text-align: center;
        border-style: dashed;
        border-color: #7495b4;
        color: #3d4864;
        border-radius: 5px;
        height: 100%;
        border-width: 1.5px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0.5em;
        padding: 1em;
        .dropzone-zone {
          width: 100%;
          height: 100%;
          margin-top: 1em;
        }
      }

      b {
        font-size: 1.2em;
      }

      p {
        font-size: 12px;
      }

      .upload-icon {
        background-image: url(../images/upload-2.png);
        width: 4em;
        height: 4em;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto;
        margin-bottom: 0.5em;
      }
    }

    .new-image-preview {
      text-align: center;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      .image-container {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .btn-primary {
        margin-top: 1em;
      }
    }
  }
}

h2.no-margin-bottom {
  color: #000;
}

.alert.empty {
  height: 3em;
}

.filter-padding {
  padding: 0 2em 0 1em;
  padding-bottom: 4em;
}

.button-logins {
  button.btn.btn-primary {
    width: 100%;
  }

  .btn-primary {
    background-color: #0044de;
    /* border-color: #2438a7; */
  }
}

.login-page .form-holder .form form {
  max-width: 100%;
}

input:invalid {
  box-shadow: none;
}

.logo {
  h1 {
    font-weight: 300;
  }

  span {
    color: #bad333;
    font-weight: 500;
  }
}

.recent-updates {
  h5 {
    font-weight: 600;
    font-size: 12px;
  }

  p {
    font-size: 0.7em;
  }

  .item {
    padding: 0.5em 21px;
  }
}

section.dashboard-counts {
  .row {
    padding: 0;
    margin: 0;
  }

  .title {
    font-size: 0.9em;
  }
}

section {
  padding: 17px 0;
}

.boxed-in-property {
  display: flex;
  flex-flow: column;
}

.flex-this {
  display: flex;
  /* align-items: flex-end; */
  justify-content: center;

  button.btn.btn-danger {
    margin-right: 1em;
  }
}

.space-evenly {
  justify-content: space-evenly;
}

/****Property Summary Table ******/

.adlist-summary-table {
  // Main container: initialize the flex, direction is row
  .table-row {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-grow: 0;
    -webkit-flex-grow: 0;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    width: 100%;
    padding: 6px 21px;
    align-items: center;
    font-size: 13px;
    color: #787878;
    font-size: 12px;
    color: #29292d;
    border-bottom: 1px solid #f9fbfd;
    border-collapse: collapse;
    background-color: #f9fbfd;

    &:nth-of-type(odd) {
      background-color: #fff;
    }
  }

  .table-row.header {
    background-color: #fff;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
    text-transform: uppercase;
    color: #383f4b !important;
    font-size: 12px !important;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #ebeff4;

    .name {
      color: #383f4b !important;
      font-size: 14px !important;
    }
  }

  .text {
    flex-grow: 1;
    -webkit-flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 20px;
  }

  .gender {
    width: 4%;
    display: flex;

    justify-content: space-evenly;

    i.fa.fa-female {
      font-size: 1.9em;
      color: #e62868;
      background: -webkit-linear-gradient(#e91e63, #f7757b);
      background-clip: text;
      -webkit-background-clip: text;
    }

    i.fa.fa-male {
      font-size: 1.9em;
      color: #3d53ce;

      background: -webkit-linear-gradient(#2b6a9d, #03a9f4);
      background-clip: text;
      -webkit-background-clip: text;
    }
  }

  .ad-progress {
    width: 10%;
    padding-right: 1em;
    padding-left: 1em;

    b {
      font-weight: bold;
      padding-right: 4px;
    }
  }

  .id {
    width: 3%;
    display: flex;
    justify-content: space-evenly;
  }

  .edit {
    display: flex;
    width: 7%;
    justify-content: space-evenly;
  }

  .age {
    width: 5%;
    text-align: center;
  }

  .day {
    width: 13%;
    display: flex;
    justify-content: space-evenly;
  }

  .status {
    width: 4%;
    display: flex;
    justify-content: space-evenly;
    padding-right: 1em;
  }

  .ad {
    width: 7%;
    display: flex;
    justify-content: space-evenly;
  }

  .weather {
    width: 10%;

    .wi {
      font-size: 1.5em;
    }
  }
  .property-count,
  .screen-count {
    width: 7%;
    text-align: center;
  }
  .ad-details {
    width: 12%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    padding-right: 1em;

    b,
    strong {
      font-weight: bolder;
      font-weight: bold;
    }
  }

  .table-row {
    border-bottom: 1px solid #f3f3f3;
    border-collapse: collapse;
  }

  .summarize {
    width: 8%;
    justify-content: space-evenly;
    display: flex;
  }

  i.fa.fa-pencil.fa-lg {
    color: #63c867;
    font-size: 22px;
    cursor: pointer;
  }

  i.fa.fa-copy.fa-lg {
    color: #525252;
    font-size: 22px;
    cursor: pointer;
  }

  i.fa.fa-times.fa-lg {
    color: #ff5722;
    font-size: 22px;
    cursor: pointer;
  }

  .more-info {
    color: #1a87dd;
    font-weight: bolder;
    cursor: pointer;
  }
}

.weather {
  display: flex;
  justify-content: space-evenly;

  .wi {
    color: #9634dc;

    &.wi-day-sunny {
      color: #ff6200;
    }

    &.wi-day-cloudy {
      color: #ff9305;
    }

    &.wi-cloudy {
      color: #da8cff;
    }

    &.wi-rain {
      color: #38b3ff;
    }

    &.wi-snowflake-cold {
      color: #91a7ff;
    }
  }
}

.status.Active {
  color: #ffffff;
  background-color: #46c44b;
  text-align: center;
  border-radius: 13px;
  padding: 3px 12px;
  text-transform: uppercase;
  font-weight: 500;
  /* width: 7em; */
  //margin: 0 auto;
  font-size: 10px;
}

/****Property Summary Table ******/

.property-summary-table {
  // Main container: initialize the flex, direction is row
  .table-row {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-grow: 0;
    -webkit-flex-grow: 0;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    width: 100%;
    padding: 6px 21px;
    align-items: center;
    font-size: 13px;
    color: #787878;
    font-size: 12px;
    color: #6e6969;
    border-bottom: 1px solid #f9fbfd;
    border-collapse: collapse;
    background-color: #f9fbfd;

    &:nth-of-type(odd) {
      background-color: #fff;
    }
  }

  .text {
    flex-grow: 1;
    -webkit-flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 20px;
  }

  .text {
    width: 180px;
  }

  .num {
    width: 180px;
  }

  .table-row.header {
    background-color: #fff;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
    text-transform: uppercase;
    color: #383f4b !important;
    font-size: 14px !important;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #ebeff4;
  }
  .name {
    font-weight: bolder;
    color: #5a5858;
  }

  .column {
    width: 25%;
  }
}

/****TABLE ******/

.noscroll-table-wrapper {
  margin: 0 auto;
  overflow-x: hidden;

  .general-table {
    width: 100%;
    display: table;
    font-size: 12px;
  }

  @media screen and (max-width: 580px) {
    .general-table {
      display: block;
    }
  }

  .row > * {
    width: auto;
  }

  .row {
    display: table-row;
    background: #f6fbff;
  }

  .row:nth-of-type(odd) {
    background: #ffffff;
  }

  .row.header {
    font-weight: 900;
    color: #3e3e3e;

    /* background: #ea6153; */
    .cell {
      padding: 6px 2em 6px 0em;
      display: table-cell;
      /* border-bottom-color: #4f4b4b; */
      /* border-bottom-style: solid; */
      /* border-bottom-width: 2px; */
      font-size: 1em;
      vertical-align: middle;
    }
  }

  .row.green {
    background: #27ae60;
  }

  @media screen and (max-width: 580px) {
    .row {
      padding: 14px 0 7px;
      display: block;
    }

    .row.header {
      padding: 0;
      height: 6px;
    }

    .row.header .cell {
      display: none;
    }

    .row .cell {
      margin-bottom: 10px;
    }

    .row .cell:before {
      margin-bottom: 3px;
      content: attr(data-title);
      min-width: 98px;
      font-size: 10px;
      line-height: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: #969696;
      display: block;
    }
  }

  .cell {
    padding: 8px 2em 8px 0em;
    display: table-cell;
    border-bottom-color: #ebf2f9;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    vertical-align: middle;
  }

  @media screen and (max-width: 580px) {
    .cell {
      padding: 2px 16px;
      display: block;
    }
  }

  .textLink {
    font-weight: bold;
    color: #376bff;
    cursor: pointer;
  }

  .editBox {
    display: flex;
  }

  button {
    font-size: 12px;
  }

  .text {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    font-size: 11px;
  }

  .no-results {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    color: #8e9aa0;
    padding-top: 1em;

    i.fa.fa-search {
      font-size: 5em;
      padding-bottom: 14px;
    }
  }
}

/**
ROUND BUTTON
**/

.circle img {
  margin: auto;
  width: 30px;
  height: 30px;
}

.center-text {
  text-align: center;
}

.display-text {
  font-family: "Gilroy-Bold";
  color: getColor(primary-blue);
}

//**FORM

/* form starting stylings ------------------------------- */

.form-container {
  padding: 2em;
}

.flex {
  display: flex;
}

.flex-end {
  display: flex;
}

img {
  width: 100%;
  height: 100%;
}

.flex-align {
  align-items: center;
  display: flex;
}

path.rc-progress-line-trail {
  stroke-width: 3px;
  stroke: #e2e2e2;
}

path.rc-progress-line-path {
  stroke-width: 3px;
}

.adlist-step {
  background-color: #fff;
  width: 100%;
  margin-top: 0.45em;
  border: 1px solid #e1e8f2;
  margin-bottom: 0em;
  flex: 1 1 auto;

  h1 {
    font-size: 29px;
    color: #3b3f44;
    font-weight: initial;
    text-transform: unset;
    font-size: 22px;
    font-weight: 700;
    margin-left: 0;
    margin-right: 1em;
  }

  .adlist-header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 17px; */
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #e8edf2;
    padding: 0.5em 1em;

    .edit {
      padding: 8px 23px;
      background-color: #26ae2b;
      border-radius: 25px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      font-weight: normal;
      margin-right: 2em;

      &:hover {
        background-color: #238327;
      }
    }
  }

  h4 {
    font-size: 1.5em;
    color: #3b3f44;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 23px;
  }

  .summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 82px 8px 32px;
    font-size: 14px;
    font-weight: bolder;
    color: #383b4f;
    border-top-style: solid;
    border-width: 1px;
    border-color: #eceff5;
    text-transform: uppercase;
    font-weight: bolder;
  }

  .empty {
    text-align: center;
    padding-top: 4em;
    font-size: 2em;

    padding-bottom: 7em;

    h1 {
      color: #6f6f70;
      padding: 0;
      margin: 0;
    }

    i.fa {
      font-size: 4em;
      color: #d2d2d2;
      padding-bottom: 19px;
    }

    h2 {
      padding: 1em;
      /* margin-top: 1em; */
      background-color: #4caf50;
      width: 13em;
      text-align: center;
      margin: 0 auto;
      margin-top: 27px;
      color: #fff;
      font-weight: inherit;
      border-radius: 5px;
      font-size: 20px;
      cursor: pointer;
    }
  }

  p {
    margin-top: 2px;
    color: #010101;
    font-weight: lighter;
    font-size: 12px;
    display: flex;
  }
}

.base-flex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.image-preview-widget {
  img {
    width: 100%;
    height: 100%; // border-radius: 3px;
    // border-style: solid;
    // border-width: .2em;
    // border-color: #fff;
  }

  video {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
}

p.pre {
  white-space: pre-line;
}

p {
  margin: 0;
}

.property-selection-step {
  background-color: #fff;
  width: 100%;
  margin-top: 0.45em;
  border: 1px solid #e1e8f2;
  margin-bottom: 0em;
  flex: 1 1 auto;

  h1 {
    font-size: 29px;
    color: #3b3f44;
    font-weight: initial;
    text-transform: unset;
    font-size: 22px;
    font-weight: 700;
    margin-left: 0;
    margin-right: 1em;
  }

  .property-header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 17px; */
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #e8edf2;
    padding: 0.5em 1em;

    .search-bar {
      margin-right: 0;
      font-size: 14px;
      padding-right: 1em;
    }
  }

  h4 {
    font-size: 1.5em;
    color: #3b3f44;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 23px;
  }

  .empty {
    text-align: center;
    padding-top: 4em;
    padding-bottom: 7em;

    h1 {
      color: #6f6f70;
      padding-top: 0;
      margin-top: 0;
    }
  }

  i.fa.fa-building {
    font-size: 4em;
    color: #d2d2d2;
    padding-bottom: 19px;
  }

  p {
    color: #5d5c5c;
    font-weight: lighter;
    font-size: 15px;
  }
}

.action-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
}

.material {
  position: relative;
  padding: 0;
  /* margin: 5px; */
  border: none;
  overflow: visible;

  input {
    display: block;
    padding: 7px 11px;
    border: 1px solid #d0d7e1;
    background: #f7f8fa !important;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    font-size: 102%;
    cursor: text;
    background-color: transparent;
    color: #343c45;

    &::-webkit-input-placeholder {
      transition: color 300ms ease;
    }

    &:not(:focus)::-webkit-input-placeholder {
      color: transparent;
    }
  }

  hr {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 2px;
    border: none;
    background: #607d8b;
    font-size: 1px;
    will-change: transform, visibility;
    transition: all 200ms ease-out;
    transform: scaleX(0);
    visibility: hidden;
    z-index: 10;
  }

  input:focus ~ hr {
    transform: scaleX(1);
    visibility: visible;
  }

  label {
    position: absolute;
    top: 10px;
    left: 0px;
    color: #bad333;
    transform-origin: 0 -139%;
    transition: transform 301ms ease;
    pointer-events: none;
    font-weight: 202;
    transform: scale(0.55);
    font-size: 1.7em;
  }

  /*
    input:focus ~ label,
    input:valid ~ label {
        transform: scale(0.55);
        font-size: 1.7em;
    }
    */
}

.react-bs-table {
  tr {
    cursor: pointer;
  }
}

.btn-group.btn-group-sm {
  button.btn.btn-primary {
    margin-bottom: 9px;
    border-radius: 1px;
    background-color: #4158e7;
  }
}

/* setup tooltips */

.tooltip {
  position: relative;
  opacity: 1;
}

.tooltip:before,
.tooltip:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.weekday-divs {
  display: flex;
  width: 15em;
  justify-content: space-between;
  padding-left: 1em;
}

.weather-divs {
  display: flex;
  width: 26em;
  justify-content: space-between;
  padding-left: 1em;
}

.local-ad-preview {
  /* margin: 0 auto; */
  /* text-align: center; */
  margin-left: 3em;
}

.title {
  font-size: 12px;
  padding-bottom: 5px;
  padding-top: 6px;
}

.sk-circle {
  margin: 2em auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.subtitle {
  display: flex;
  align-items: center;

  .error {
    color: #dc3545;
    font-size: 12px;
    padding-left: 9px;
    padding-bottom: 5px;
    padding-top: 8px;
  }
}

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #4155c7;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.config-modal {
  .rodal-dialog.rodal-zoom-enter {
    width: 30em !important;
    text-align: center;
    top: -7em;
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0.5em;
  }

  .modal-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2em;
    /* width: 30em; */
  }

  .save {
    width: 10em;
    background-color: #4ca850;
    margin-right: 1em;
    padding: 0.5em;
    color: #fff;
    cursor: pointer;
  }

  .cancel {
    width: 10em;
    background-color: #ffffff;
    padding: 0.5em;
    color: #3f51b5;
    cursor: pointer;
    border-color: #3f51b5;
    border-style: solid;
    border-width: 1px;
  }
}

button.default.date-range-toggle.btn.btn-default {
  border-radius: 0;
  background-color: #4355f0;
}

.Select-control {
  background-color: #fff;
  /* border-color: #d0d7e1; */
  border-radius: 0px;
  border: 1px solid #dae0e7;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 40px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.error {
  color: #dc3545;
  font-size: 13px;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 38px;
}

//CAMPAIGN STEP RELATED
.ad-form-container {
  background-color: #fff;
  margin: 3px auto;
  width: 96%;
  margin-top: 1em;
  -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  flex-direction: column;
  margin-bottom: 2em;

  .header {
    font-size: 1.3em;
    font-weight: 500;
  }

  .ad-content {
    display: flex;

    .ad-left {
      width: 25%;

      h3 {
        padding: 0;
        margin: 0;
        text-align: center;
        padding-top: 3em;
        font-size: 2em;
        /* font-weight: initial; */
        font-size: 27px;
        color: #35393a;
        font-weight: unset;
        /* text-transform: uppercase; */
      }

      background-color: #f5f5f5;

      .no-image-preview {
        width: 272px;
        height: 196px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-style: dashed;
        border-color: #c2baba;
        color: #928989;
      }

      .step3-dropzone-button {
        background-color: #4158e7;
        padding: 0.5em 2em;
        font-size: 20px;
        border-radius: 0px;
        color: #fff;
        cursor: pointer;
      }

      .preview-section {
        display: flex;
        flex-flow: column;
        align-items: center;
        padding-top: 2em;

        .allowed-dimensions {
          padding-top: 15px;
          display: flex;
          flex-flow: column;
          /* justify-content: center; */
          align-items: center;
          font-size: 14px;
          padding-bottom: 1em;
          color: #4d5052;
        }
      }
    }

    .ad-right {
      width: 73%;
      padding: 1em 3em;
      overflow: auto;

      .simple-flex {
        display: flex;
        align-items: center;

        p {
          margin: 0;
          font-weight: 600;
          font-size: 16px;
          color: #494848;
          font-weight: bolder;
          margin-right: 0.5em;
        }
      }
    }
  }

  .action-button-container {
    display: flex;
    justify-content: space-between;
    background-color: #2f40ab;
    /* height: 42px; */
    align-items: center;
    padding: 3px 30px;
    color: white;
    font-size: 17px;
    height: 2.6em;

    .info {
      color: #fff;
      font-size: 1.3em;
    }

    .save {
      background-color: #67e02b;
      padding: 3px 1.5em;
      margin: 5px;
      cursor: pointer;
      color: #000;

      &.disabled {
        color: #f8f8f8 !important;
        background-color: #a8a8a8;
        cursor: not-allowed;
      }
    }

    .cancel {
      background-color: #556aea;
      color: #fff;
      padding: 3px 1.5em;
      margin: 5px;
      cursor: pointer;
    }

    .action {
      display: flex;
      width: 13em;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.react-bootstrap-table {
  th .order-4:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
  }

  th .order-4:after {
    content: "\2193";
    opacity: 0.4;
  }

  th .caret-4-asc:before {
    margin-left: 3.5px;
    content: "\2191";
  }

  th .caret-4-desc:after {
    margin-left: 3.5px;
    content: "\2193";
  }

  .text-filter[placeholder] {
    text-overflow: ellipsis;
  }
}

.ad-icon-row {
  a.thumbnail {
    width: 4em;
    height: 4em;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    &.Male {
      background-image: url(../images/man.png);
    }

    &.Female {
      background-image: url(../images/woman.png);
    }

    &.All {
      background-image: url(../images/all.png);
    }

    &.active {
      background-color: #58ec56;
    }

    p {
      color: #696868;
    }
  }

  .main-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2em;

    p {
      font-size: 13px;
    }
  }
}

.button-effects {
  .flex-align {
    display: flex;
    align-items: center;
  }

  a.hvr-reveal {
    cursor: pointer;
    background-color: #e5e5e5;
    padding: 4px 6px;
    font-size: 12.6px;
    color: #747474;

    &.active {
      background-color: #59eb6c;
      color: #083a07;
    }
  }

  .time-toggle {
    /* padding-left: 1em; */
    border-radius: 6px;
    background-color: #ced4db;
    margin-left: 1em;
    /* text-align: center; */
    font-size: 14px;
    height: 2em;
    width: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #000;
  }
}

.small-icon-row {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  width: 22em;

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-right: 2em; */
    margin-bottom: 1em;

    .wi {
      font-size: 2em;
      line-height: 1.3;
      cursor: pointer;
      width: 1.7em;
      text-align: center;
      padding: 0.1em;
      height: 1.5em;

      &.wi-day-cloudy:before {
        content: "\f002";
        margin-top: 5px;
        display: flex;
      }

      &.wi-rain:before {
        display: flex;
        /* align-items: center; */
        justify-content: center;
        margin-top: -4px;
      }
    }

    .active {
      background-color: #59eb6c;
      color: #345838;
    }
  }

  .single-weather-icon {
    display: flex;
    flex-direction: column;
    align-items: center;

    .wi {
      font-size: 2.5em;
    }
  }

  p {
    padding-top: 4px;
    font-size: 12px;
  }

  a.thumbnail {
    margin-bottom: 0;
    cursor: pointer;
    padding: 0.2em;
    width: 2em;
    text-align: center;
    height: 1.6em;
    display: flex;
    align-items: center;
    justify-content: center;

    &.sunny {
      background-image: url(../images/sunny.png);
    }

    &.rain {
      background-image: url(../images/raining.png);
    }

    &.overcast {
      background-image: url(../images/overcast.png);
    }

    &.partial {
      background-image: url(../images/partial.png);
    }

    &.snow {
      background-image: url(../images/snowing.png);
    }

    &.active {
      background-color: #59eb56;
    }
  }
}

.rodal.rodal-fade-enter.ad-info {
  .rodal-dialog.rodal-zoom-enter {
    overflow: auto;
    top: -2em;
  }
}

.iconLinkList {
  display: flex;
  justify-content: center;
}

.iconLink {
  i {
    cursor: pointer;
    color: #2464f0;
    font-size: 21px;
  }
}

.date-range-toggle {
  .wi {
    font-size: 1.5em;
  }
}

/**/
.screen-preloader {
  padding: 6em;
  font-size: 33px;
  margin-left: -2.5em;
  padding-top: 3em;
  color: #ffffff;
  font-weight: 100;
  line-height: 2em;
  width: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  margin-top: 1em;

  p {
    margin: 0;
    padding: 0;
    font-size: 26px;
  }

  &.wide {
    margin-left: 0.5em;
    margin-top: 1em;
  }
}

.demo-property {
  .rodal-dialog.rodal-zoom-enter {
    width: 50em !important;
    overflow: auto;
    height: 31em !important;
  }

  .error {
    padding-bottom: 0.5em !important;
  }

  .table-height {
    height: 18em;
    /* background-color: red; */
    overflow: auto;
  }

  .seletion-button {
    padding: 1em;
    background-color: #4caf50;
    width: 10em;
    text-align: center;
    /* border-style: solid; */
    color: #fff;
    cursor: pointer;
  }
}

.demo-weather {
  .rodal-dialog.rodal-zoom-enter {
    width: 38em !important;
    overflow: auto;
    height: 20em !important;
  }
}

.seletion-button {
  display: flex;
  justify-content: space-between;
}

.error {
  color: red !important;
}

.scenario-results {
  padding: 1em;
  background-color: #001a21;
  color: #e3eaef;
  font-weight: 300;
  font-size: 1.2em;
  /* margin-left: 0em; */
  padding-left: 1.5em;
  position: relative;
  z-index: 10;
  width: 100%;

  p {
    color: #cddc39;
    font-size: 15px;
    margin: 0;
  }

  .flex-end {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  button.btn.btn-success.btn.btn-default {
    background-color: #1e8723;
    border-color: #009688;
  }

  .error {
    color: #ffac00;
    font-size: 16px;
    font-weight: initial;
  }
}

.demo-day {
  top: 12px;
  position: relative;
  width: 8em;
  z-index: 11;
  left: 350px;
  font-size: 12px;
  width: 10em;
  /* text-align: center; */
  color: #152a47;
  /* padding: 0.5em; */
  font-weight: 600;
  /* background-color: red; */
}

.demo-ads {
  .rodal-dialog.rodal-zoom-enter {
    width: 83em !important;
    height: 34em !important;
    overflow: auto;
  }
}

tr.hoverable {
  cursor: pointer;
}

//Image Browser Widget
.false-circle {
  height: 1.2em;
  width: 1.2em;
  background-color: #cacaca;
  margin: 0 auto;
  border-radius: 1em;
}

.true-circle {
  height: 1.2em;
  width: 1.2em;
  background-color: #38df36;
  margin: 0 auto;
  border-radius: 1em;
}

.table-selecting-widget {
  width: 100%;
  max-height: 15em;
  overflow: auto;
  border-style: inset;

  tr {
    cursor: pointer;
    border-style: solid;
    border-color: #eaeaea;
    border-width: 1px;
  }

  thead {
    tr {
      background-color: getColor(primary-blue, light);
    }
  }

  th {
    padding: 0.5em 0;
    color: #fff;
  }

  td {
    padding: 0 0.3em;
    vertical-align: middle;
    font-size: 12px;
  }

  .highlighted {
    background-color: rgb(190, 226, 117) !important;
    margin: 1px !important;
  }
}

.selection-buttons {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 11px;

  button.btn.btn-success.btn-lg {
    font-size: 16px;
    border-radius: 1px;
    padding: 0.7em;
    border-style: solid;
    border-color: shade($_turquoise, $darken);
  }
}

.fillout-form {
  background-color: #fff;
}

.color-picker-group {
  display: flex;

  .text-form-widget {
    margin-right: 20px;
  }
}

.form-container {
  .checkbox-style p {
    font-size: 14px;

    .text-form-widget {
      .title {
        margin-top: 9px;
      }
    }
  }
}

.notice-background-images {
  height: 19em;
  width: 100%;
  overflow: auto;
  background-color: rgb(248, 248, 249);
  border-style: inset;
  border-color: #e4e4e4;
  padding: 0.5em 0;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

.column-header {
  font-size: 17px;
  font-weight: inherit;
}

.fillOut-form {
  background-color: #eef1f3;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 3em;

  .embedded-padding {
    padding: 2em 3em;
  }

  h1 {
    font-size: 1.5em;
    font-weight: normal;
    margin: 0;
    /* padding: 0.5em 0 0.2em; */
    /* text-transform: uppercase; */
    padding-top: 12px;
    text-align: center;
    /* font-size: 2em; */
    color: #fff;
    padding-bottom: 4px;
    text-transform: none;
    font-size: 26px;
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .filter-button {
    font-size: 14px;
    font-weight: bold;
    padding: 0.5em 2em;
    margin: 0;
    color: #ffffff;
    background-color: #2196f3;
    border-radius: 1em;
    cursor: pointer;
  }

  .header {
    background-color: #673ab7;
    color: rgba(255, 255, 255, 0.87);
    padding: 0.3em 2em;
    text-align: center;

    .fa-5x {
      font-size: 2.5em;
      padding-right: 0.3em;
    }
  }

  .form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    align-items: flex-end;
    width: 78em;
    margin: 0 auto;
    margin-top: 1em;
    padding: 1em 3em;

    .view-form {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      padding: 2em;

      h3 {
        padding-bottom: 0.5em;
      }

      .form-ad-preview {
        width: 100%;
        display: flex;
        column-gap: 2em;
      }
    }
  }

  .campaign-form-container {
    width: 62em;
    margin: 0 auto;
    margin-top: 2em;

    .inner-padding {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      background-color: #fff;
      -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
      padding: 3em 4em;
    }
  }

  .step4-container {
    width: 68em;
    margin: 0 auto;
    margin-top: 2em;

    .btn-info {
      /* border-color: #9E9E9E; */
      /* border-color: #9E9E9E; */
      border-style: solid;
      background-color: transparent;
      color: #fff;
      border-color: #46a74a;
      -webkit-transition: all ease-out 0.5s;
      -moz-transition: all ease-out 0.5s;
      -o-transition: all ease-out 0.5s;
      transition: all ease-out 0.5s;
      border-radius: 1px;
      background-color: #32a937;
    }

    .flex-section {
      padding: 0em 1em;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
      /* background-color: red; */
      /* align-items: flex-end; */
      width: 68em;
      margin: 0 auto;
      /* margin-top: 2em; */
      padding: 2em 3em;
      margin-bottom: 2em;
      background-color: #fff;
    }
  }

  .header-column {
    width: 100%;

    .column-header {
      h2 {
        font-size: 16px;
        /* background-color: #1e5e94; */
        display: inline-block;
        margin: 0;
        padding: -0.5em;
        color: #1e1d1d;
        text-transform: uppercase;
      }
    }
  }

  .small-header-column {
    width: 100%;
    padding-top: 1.5em;
    padding-bottom: 0.5em;

    h2 {
      font-size: 15px;
      margin: 0;
    }
  }
}

.text-form-widget {
  .title {
    font-size: 13px;
  }
}

.item.d-flex.align-items-center {
  height: 7em;
}

.search-bar {
  height: 27px;
  margin-top: 4px;
  display: flex;
  align-items: center;

  i.fa.fa-search {
    font-size: 21px;
    color: #979797;
  }

  input#header-search {
    width: 100%;
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    border-bottom-color: #adadad;
    border-bottom-width: 1px;
  }

  input[type="text"] {
    width: 100%;
    border-style: none none solid none;
    border-color: #979797;
    border-width: 1px;
    padding-left: 0.5em;
  }
}

.campaign-list {
  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
  }

  .iconLink {
    i {
      font-size: 24px;
    }
  }
  i.fa.fa-lg.fa-area-chart {
    background: -webkit-linear-gradient(#0037ff, #0bcae8);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &.no-link {
      cursor: default;
      color: #a1a1a1;
      -webkit-text-fill-color: unset;
    }
  }

  i.fa.fa-lg.fa-pause {
    &.no-link {
      cursor: default;
      color: #a1a1a1;
    }
  }

  i.fa.fa-lg.fa-building {
    background: -webkit-linear-gradient(#cc00ff, #108beb);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  i.fa.fa-lg.fa-image {
    background: -webkit-linear-gradient(#ff56c3, #ff9842);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  i.fa.fa-lg.fa-television {
    background: -webkit-linear-gradient(#61d8a1, #009688);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  i.fa.fa-lg.fa-bar-chart {
    background: -webkit-linear-gradient(#da00ff, #008eff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-link {
    text-decoration: underline;
  }

  .campaign-title {
    font-weight: bolder;
  }

  .filter-section {
    display: flex;
    padding-bottom: 9px;
    align-items: center;
    padding-top: 5px;
    margin-left: 1em;

    .header {
      text-transform: uppercase;
      font-weight: bolder;
      color: #3a3a3a;
      margin-right: 0.5em;
      margin-left: 1em;
      font-size: 11px;
    }

    .progress-filter {
      .progress-bar {
        width: 8em;
        background-color: transparent;
        width: 15em;
        margin-top: -10px;
        margin-left: 12px;

        .rc-slider-handle {
          margin-left: -6px;
          margin-top: -3px;
          width: 10px;
          height: 10px;
        }

        .rc-slider-mark-text {
          color: #999;
          font-size: 10px;
          margin-top: -3px;
          color: #525252;
        }

        .rc-slider-handle {
          border: solid 2px #03b1f4;
        }

        .rc-slider-dot-active {
          border-color: #03b1f4;
        }

        .rc-slider-track {
          background-color: #03b1f4;
        }

        .rc-slider-disabled {
          background-color: transparent;

          .rc-slider-rail {
            border-color: #bdbdbd;
          }

          .rc-slider-track {
            background-color: #bdbdbd;
          }

          .rc-slider-dot-active {
            border-color: #bdbdbd;
          }

          .rc-slider-mark-text-active {
            border-color: #bdbdbd;
          }

          .rc-slider-mark-text-active {
            color: #989191;
          }

          .rc-slider-handle {
            border: solid 2px #bdbdbd;
          }
        }
      }

      i.fa.fa-question-circle {
        font-size: 18px;
        margin-left: 3px;
      }

      .__react_component_tooltip.type-light p {
        font-size: 15px !important;
      }

      .progress-option {
        margin-left: 2.4em;
        font-size: 11px;
        color: #fff;
        /* font-weight: bolder; */
        border-style: solid;
        padding: 2px 1em;
        border-width: 2px;
        border-radius: 3em;
        cursor: pointer;
        font-weight: bolder;

        &.disable {
          color: #a1a1a1;
          background-color: #d8d8d8;
          border-color: #d8d8d8;
        }

        &.include {
          background-color: #04a6f4;
          border-color: #04a6f4;
        }
      }
    }

    .sort-by {
      .Select-control {
        font-size: 13px !important;
        height: 0em;
        min-width: 10em;
      }

      .Select-placeholder,
      .Select--single > .Select-control .Select-value {
        color: #636363;
        margin-top: -1px;
      }
    }
  }
}

/*********
DASHBOARD
*********/

section.dashboard-tick {
  display: flex;
  /* background-color: white; */
  justify-content: space-between;
  /* margin: 1.5em 6em; */
  margin-right: 7em;

  margin-left: 2em;

  .campaign-box {
    background-color: #fff;
    height: 27em;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

    hr {
      margin-bottom: 0;
    }
  }

  .dash-header {
    /* margin-left: 1em; */
    padding: 0.5em 0;
    font-weight: initial;
    font-size: 19px;
    padding-top: 15px;
    margin-bottom: 0;
    padding-bottom: 0;
    color: #424242;
  }

  .box {
    padding: 1em;
    width: 13em;
    background-color: #fff;
    height: 15em;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    height: 16em;

    h2 {
      font-size: 37px;
      padding-bottom: 7px !important;
    }

    i {
      font-size: 86px;
      padding: 19px 0 16px;
      color: #0000008c;

      &.fa-align-justify {
        background: -webkit-linear-gradient(#da00ff, #2196f3);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.fa-list-ul {
        background: -webkit-linear-gradient(#ffeb3b, #ff5722);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.fa-info-circle {
        background: -webkit-linear-gradient(#55ecff, #91df36);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &.live {
      i {
        background: -webkit-linear-gradient(#26c5b8, #8eec21);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 92px;
        padding: 4px 0 10px;
        color: #0000008c;
      }
    }

    &.pending {
      i {
        background: -webkit-linear-gradient(#ff9777, #ffc107);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 92px;
        padding: 4px 0 10px;
        color: #0000008c;
      }
    }

    &.complete {
      i {
        background: -webkit-linear-gradient(#317eef, #21c7ec);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 92px;
        padding: 4px 0 10px;
        color: #0000008c;
      }
    }

    &.all {
      i {
        background: -webkit-linear-gradient(#7f37ff, #ff42f0);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 92px;
        padding: 4px 0 10px;
        color: #0000008c;
      }
    }
  }

  .staff-box {
    padding: 2em 2em;
    width: 21em;
    background-color: #fff;
    height: 15em;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    height: 16em;

    i.fa.fa-lightbulb-o {
      font-size: 5em;
      padding-bottom: 8px;
      margin-left: -8px;

      background: -webkit-linear-gradient(#2196f3, #3f51b5);

      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .small-link {
      color: #376bff;
      font-weight: initial;
      cursor: pointer;
    }
  }

  .profile-box {
    padding: 2em 2em;
    width: 21em;
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    height: 16em;

    i.fa.fa-building {
      font-size: 5em;
      padding-bottom: 8px;
      margin-left: -8px;

      background: -webkit-linear-gradient(#2196f3, #3f51b5);

      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .small-link {
      color: #376bff;
      font-weight: initial;
      cursor: pointer;
    }
  }
}

/**********
CHECKBOX
***********/

.subset-2 {
  margin-left: 10px;
}

/* The container */

.checkbox-style {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 8px;
}

/* Hide the browser's default checkbox */

.checkbox-style input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #a2a2a2;
}

/* On mouse-over, add a grey background color */

.checkbox-style:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.checkbox-style input:checked ~ .checkmark {
  background-color: #4000ff;
}

.no-results {
  text-align: center;
  padding-top: 4em;
  font-size: 1.5em;
  font-weight: inherit;
  color: #585858;
  font-weight: lighter;

  i.fa.fa-search {
    font-size: 5em;
    padding-bottom: 8px;
  }

  p {
    &.search-title {
      font-size: 33px;
      color: #585858;
    }

    &.small {
      font-size: 18px;
    }
  }
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.checkbox-style input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.checkbox-style .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.flex-end {
  display: flex;
  justify-content: space-between;
}

.flex-end-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-tick {
  margin-right: 7em;

  p {
    margin: 0;
    font-weight: lighter;
  }

  .campaign-list {
    overflow: auto;
    width: 25em;
    padding: 1em;
    width: 67%;
    background-color: #fff;
    height: 28em;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    /* text-align: center; */
    /* cursor: pointer; */
  }

  .property-recent {
    padding: 1em;
    width: 38%;
    background-color: #fff;
    height: 22em;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }

  .client-recent {
    padding: 1em;
    width: 31em;
    background-color: #fff;
    height: 23em;
    overflow: auto;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
}

/*********
PROFILE
*********/

.campaign-dash-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e3e3e3;

  button.btn.fa.fa-building {
    color: #ed1760;
    font-size: 1.5em;
    padding: 0;
    margin-right: 0.5em;
    /* background: -webkit-linear-gradient(#707cff, #108beb); */
    background: -webkit-linear-gradient(#707cff, #108beb);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  button.btn.fa.fa-image {
    background: -webkit-linear-gradient(#ff56c3, #ff9842);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5em;
    padding: 0;
    margin-right: 0.5em;
  }

  button.btn.fa.fa-tv {
    background: -webkit-linear-gradient(#61d8a1, #009688);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5em;
    padding: 0;
    margin-right: 0.5em;
  }

  .state {
    font-size: 11px;
    display: inline;
    padding: 2px 8px;
    border-radius: 11px;
    color: #fff;
  }

  .time {
    font-size: 13px;
    padding-right: 7px;
    width: 11em;
  }

  .advertiser {
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      font-weight: bolder;
      color: #376bff;
      cursor: pointer;
    }
  }

  .baseState {
    width: 5em;
  }

  .info {
    width: 13em;
    padding-left: 9px;
    padding-right: 9px;
  }

  .campaign-title {
    font-weight: bolder;
    font-size: 12px;
  }

  .campaign-group-title {
    font-style: italic;
    font-size: 12px;
  }

  .mini-progress {
    width: 13em;
    padding-left: 9px;
    margin-right: 8px;

    p {
      font-size: 13px;
    }
  }
}

.advertinfo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* color: #000 !important; */
  padding-bottom: 1em;
  align-items: center;
  font-size: 13px;

  .gender {
    font-size: 2em;
    width: 3em;

    .fa.fa-female {
      color: #e91e63;
    }

    i.fa.fa-male {
      color: #3f51b5;
    }
  }

  .title {
    color: black !important;
    font-weight: bolder;
    width: 8em;
  }

  .age {
    width: 15em;
    display: flex;
    justify-content: space-between;
    /* font-size: 15px; */
    padding-right: 1em;
  }

  .day {
    width: 14em;
    display: flex;
    justify-content: space-between;
  }

  .weather {
    width: 13em;
    width: 15em;
    display: flex;
    justify-content: space-between;
    /* font-size: 15px; */
    padding-right: 1em;
    font-size: 1.5em;
    color: #9634dc;
  }
}

.login-box {
  h1 {
    margin: 0;
    padding: 0;
  }
}

a.hvr-reveal.no-hover {
  cursor: default !important;
  pointer-events: none;
}

.__react_component_tooltip.type-light {
  color: #222;
  border-width: 1px;
  border-width: 1px;
  border-color: #d8d8d8;
  padding: 2em 2em;
  font-size: 13px;
  opacity: 1 !important;

  p {
    font-size: 10px !important;
  }

  hr {
    padding-bottom: 0.3em;
  }
}

.__react_component_tooltip.type-light.border.place-top:before {
  border-top: 7px solid #d8d8d8;

  a.information-button {
    i.fa.fa-question-circle {
      padding-top: 0;
      margin-left: 3px;
    }

    hr {
      padding: 0.5em;
    }
  }

  a.information-button {
    i.fa.fa-question-circle {
      padding-top: 0;
      margin-left: 3px;
    }

    hr {
      padding: 0.5em;
    }
  }
}

.text-left.impressions-tooltip-content {
  hr {
    margin: 5px;
  }
}

.loading-Page {
  padding-top: 10em;
  width: 100%;
  height: 100%;

  p {
    text-align: center;
    font-size: 1.4em;
    color: #5a5a5a;
    font-weight: lighter;
  }

  .pageLoader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #0091fe;

    background: -moz-linear-gradient(left, #0091fe 10%, rgba(0, 145, 254, 0) 42%);
    background: -webkit-linear-gradient(left, #0091fe 10%, rgba(0, 145, 254, 0) 42%);
    background: -o-linear-gradient(left, #0091fe 10%, rgba(0, 145, 254, 0) 42%);
    background: -ms-linear-gradient(left, #0091fe 10%, rgba(0, 145, 254, 0) 42%);
    background: linear-gradient(to right, #0091fe 10%, rgba(0, 145, 254, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .pageLoader:before {
    width: 50%;
    height: 50%;
    background: #0091fe;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }

  .pageLoader:after {
    background: #f0f4f6;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

@media only screen and (max-width: 1100px) {
  .fillOut-form .form-container {
    width: 100%;
  }
}

.radio::before,
.switch::after {
  content: none;
}

.switch {
  border-width: none;
}

.btn.smaller {
  padding: 1px 1em;
  font-size: 15px;
}

.round {
  border-radius: 0 !important;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  border-style: none !important;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-date-picker-input {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.registration-page-flex {
  width: 100vw;
}
