//styles that override current react date-picker library

.react-datepicker {
  &-wrapper {
    width: 100%;
  }
  &__input-container {
    width: 100%;
  }
  &__time-list-item {
    padding: 5px 5px !important;
  }
}

@media (max-width: $mq-small) {
  .react-datepicker {
    &__time-container {
      width: 100%;
    }
    &__month-container {
      float: none;
    }
    &__time-box {
      width: 100% !important;
    }
    &__navigation--next {
      right: 0 !important;
    }
  }
}
