//top nav
.navbar-container {
  /* Height and Width of the "fixed" top bar */
  height: 64px;
  width: 100%;
  z-index: 3;
}

.elev8-navbar {
  padding: 0 1rem;
  height: inherit;
  background-color: getColor(primary-blue, darker);
  .navbar__dropdown {
    font-family: "Gilroy-Bold", bold;
    .dropdown-menu {
      font-size: 13px;
      padding: 0;
      background-color: getColor(primary-blue, darker);
    }
    .dropdown-item {
      padding: 0.5em 1em;
      color: #fff;
      &:hover {
        background-color: getColor(orange, base);
        border-radius: 0px;
      }
    }
  }
}

.vertical-impression-navbar {
  background-color: getColor(primary-blue, base);
  font-family: "Gilroy-Bold", bold;
  width: 100%;
  padding: 1.25rem 2rem;
}

.vertical-impression-navbar__filter {
  width: 100% !important;

  @media only screen and (min-width: 1280px) {
    left: calc(#{$sidebar-width} / 2);
  }
}
