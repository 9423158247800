.icons {
  &.fa {
    &.fa-male {
      color: #3d53ce;
    }
    &.fa-female {
      color: #e62868;
    }
  }
  &.wi {
    &.wi-day-sunny {
      color: #ff6200;
    }
    &.wi-day-cloudy {
      color: #ff9305;
    }
    &.wi-cloudy {
      color: #da8cff;
    }
    &.wi-rain {
      color: #38b3ff;
    }
    &.wi-snowflake-cold {
      color: #91a7ff;
    }
  }
}
