/* PADDING */
.u-pad-none {
  padding: 0 !important;
}

.u-pad-bot-none {
  padding-bottom: 0 !important;
}

.u-margin-bot-none {
  margin-bottom: 0 !important;
}

.u-pad-xsmall {
  padding: 0.5em;
}

.u-pad-small {
  padding: 1.5em;
}

.u-pad {
  padding: 3em;
}

.u-pad-large {
  padding: 4.5em;
}

.u-pad-bottom-small {
  padding-bottom: 3em;
}

.u-pad-bottom-xsmall {
  padding-bottom: 0.5em;
}
.u-pad-top-large {
  padding-top: 4.5em !important;
}

.u-pad-top-small {
  padding-top: 1.5em;
}

.u-pad-top-xsmall {
  padding-top: 0.5em;
}

.u-pad-right-xsmall {
  padding-right: 0.5em;
}

.u-pad-right-small {
  padding-right: 1em;
}

.u-pad-left-small {
  padding-left: 1em;
}

.u-pad-left-xsmall {
  padding-left: 0.5em;
}

.u-margin-auto {
  margin: 0 auto;
}

.u-margin-full-auto {
  margin: auto;
}
