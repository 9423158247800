/* TEXT ALIGNMENT */

.u-text-align-center {
  text-align: center;
}

.u-text-align-right {
  text-align: right;
}

/* TEXT SIZES */
.u-text-small {
  font-size: small;
}
.u-text-large {
  font-size: large;
}
.u-text-larger {
  font-size: larger;
}

.u-text-force-static{
  pointer-events: none;
  color: #000;
}
