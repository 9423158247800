$client-map-color: #8041d4;

//to reserve the mapbox styling on the older components
.main-map-container {
  .mapboxgl-popup-content {
    font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", Sans-serif;
    padding: 0;
    width: 180px;
  }

  .mapboxgl-popup-content-wrapper {
    padding: 1%;
  }

  .map-client-scale {
    background-color: #f2f3f5;
    border-bottom-style: solid;
    border-bottom-width: 0.1px;
    border-bottom-color: #d2d2d2;
    .input-range__track--background {
      background: #b5b5b5;
    }
    h1 {
      margin: 0 !important;
      font-size: 13px;
      font-weight: inherit;
      color: #fff;
      padding: 0;
    }
    .client-slider {
      color: #353535;
      font-weight: bold;
      padding: 1em 2em 0.6em 2em;
      .client-slider-header {
        font-size: 13.5px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
      }
      .slider-ranges {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        align-items: center;
        .link.thin {
          font-weight: 400;
        }
        .max-input {
          display: flex;
          align-items: center;
          .link {
            margin-left: 2px;
          }
          input[type="text"] {
            width: 5em;
            margin-left: 6px;
          }
          .unit {
            padding: 3.5px 1em;
            border-color: #fff;
            border-width: 1px;
            border-style: solid;
            font-size: 10px;
          }
        }
      }
      .checkbox-style {
        margin-top: 0;
      }
      .flex-this {
        display: flex;
        /* align-items: flex-end; */
        /* justify-content: center; */
        /* width: 100%; */
        justify-content: space-between;
        font-size: 11px;
        font-weight: unset;
      }
      .input-range__label--value {
        position: absolute;
        top: -1.8rem;
        display: none;
      }
      .input-range {
        margin-top: 3px;
      }
      .input-range__label--min,
      .input-range__label--max {
        bottom: -1rem;
        position: absolute;
        display: none;
      }
    }
  }

  .mapboxgl-popup-content h3 {
    background: #2a00e7;
    color: #fff;
    margin: 0;
    display: block;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    font-weight: 700;
    margin-top: -15px;
    font-size: 1em;
  }

  .mapboxgl-popup-content h3.network-color {
    background: $client-map-color;
  }

  .mapboxgl-popup-content h4 {
    margin: 0;
    display: block;
    padding: 10px;
    font-weight: 400;
    font-size: 15px;
  }

  .mapboxgl-popup-content div {
    padding: 10px;
  }

  .mapboxgl-map {
    font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: auto !important;
    margin-top: 88px;
    margin-left: 14.8em;
  }
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  left: -50.5em;
  /* min-width: 50%; */
  /* margin-left: 25%; */
  top: -6.8em;
  width: 26em;
}

.marker {
  // background-image: url("../images/mapbox-icon.png");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

/*****************************MAP SPECIFIC INTERFACE *************/

.map-view {
  .filter-section {
    margin-top: 0.5em;
    color: #fff;
    position: absolute;
    z-index: 100;
    font-weight: bolder;
    bottom: 4em;
    display: flex;
    bottom: 2.4em;
    margin-left: 0.5em;
    .property-filter {
      padding: 4px 16px;
      font-size: 13px;
      border-radius: 1em;
      background-color: #121b69;
      margin-right: 1em;
      cursor: pointer;
    }
  }

  .no-properties {
    position: relative;
    width: 12em;
    top: 9em;
    left: 7em;
    font-size: 1.7em;
    font-weight: inherit;
    background-color: #232323bf;
    padding: 1em;
    border-radius: 1em;
    color: #fff;
    text-align: center;
  }

  .autocomplete-search {
    position: absolute;
    margin-top: 5px;
    margin-left: 7px;
    top: 6em;
    display: flex;
    .fa.fa-search {
      background-color: #372dbd;
      padding: 7.5px;
      color: #fff;
      height: 31px;
    }

    input.location-search-input {
      width: 27em;
      font-size: 13px;
      padding: 5px 9px;
      padding-top: 5px;
      padding-right: 9px;
      padding-bottom: 5px;
      padding-left: 9px;
      border-style: solid;
      border-width: 1px;
      border-color: #bfbfbf;
    }
    .suggestion-item {
      font-size: 13px;
      padding-left: 11px;
      padding-bottom: 5px;
      padding-right: 11px;
    }

    .suggestion-item--active {
      font-size: 13px;
      padding-left: 11px;
      padding-bottom: 5px;
      background-color: #d1d5d6;
    }
  }

  .client-container {
    background-color: white;
    margin-top: 0.5em;
    margin-left: 0.5em;
    font-size: 13px;
    border-radius: 0.5px;
    background-color: #ffffff;
    color: #fff;
    position: absolute;
    z-index: 100;
    font-weight: bolder;
    color: #909090;
    font-weight: bolder;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    right: 4em;
    width: 28em;
    .rodal-dialog {
      top: -22em;
    }
    .header {
      background-color: #5800bb;
      color: #fff !important;
      display: flex;
      padding: 3px 2em;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-size: 18px;
      }
      .button {
        color: #ffffff;
        padding: 5px 2em;
        border-style: solid;
        border-width: 1px;
        border-radius: 22px;
        background-color: #6100e3;
        cursor: pointer;
      }
    }
    .title {
      font-size: 16px;
      color: #ffffff !important;
      padding-bottom: 5px;
      padding-top: 6px;
      // border-bottom-style: solid;
      // border-bottom-width: 1px;
      background-color: $client-map-color;
      text-align: center;
    }
    p {
      margin: 0;
      font-weight: initial;
    }
    .empty {
      padding: 1em;
      color: #3e3e3e;
    }

    .property-list {
      max-height: 35em;
      overflow: auto;
    }

    .item {
      padding: 0.5em 2em;
      border-bottom-color: gainsboro;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      font-size: 11px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .delete {
        i.fa {
          color: red;
          font-size: 1.7em;
          cursor: pointer;
        }
      }

      .section {
        b {
          font-weight: bold;
          color: $client-map-color;
          font-size: 12px;
          cursor: pointer;

          &.distance {
            color: #333333;
            font-size: 11px;
            cursor: default;
          }
        }

        p {
          font-size: 11px;
          color: black;
          font-weight: initial;
        }
      }

      .delete {
        margin-left: 22px;
      }
    }
  }
}

.main-map-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .main-block {
    display: flex;
    flex: 1;
    background-color: #fff;

    .filter-container {
      background-color: #fff;
      width: 11.5em;
      border-right-color: #e1e1e1;
      border-right-style: solid;
      border-right-width: 1px;
      overflow: auto;
      z-index: 1;

      .choose-view {
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 0.3em 0em;
        background-color: #121b69;
        border-style: ridge;
        color: #fff;
        cursor: pointer;
        border-color: #292d4b;
        font-weight: bold;

        p {
          font-size: 15px;
        }

        .fa {
          font-size: 2em;
        }
      }

      li {
        width: 50%;
        text-align: center;
        font-size: 15px;

        a {
          color: #2d05ff;
        }

        &.active {
          a {
            background-color: #2d05ff;
            color: #fff !important;
            width: 100%;
          }
        }
      }
    }

    .interactive-container {
      flex: 1;
      background-color: whitesmoke;
    }
  }

  .summary-l-block {
    background-color: #0f0f46;
    padding: 0px 13px;
    display: flex;
    justify-content: space-between;
    font-size: 21px;
    /* padding-top: 12px; */
    align-items: center;
    color: #fff !important;
    /* padding-top: 12px; */
    color: #425159;
    margin-bottom: 0em;
    font-weight: bold;

    h4 {
      padding: 0.5em;
      font-size: 1em;
      font-weight: normal;
    }
    .l-action-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 1em;

      .button {
        background-color: #2fa312;
        color: #fff;
        border-style: solid;
        margin-right: 1em;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 0.2em 1.5em;
        font-size: 15px;

        &.export {
          background-color: #d27a00;

          &:hover {
            background-color: #9c5b00;
          }
        }

        &.save {
          &:hover {
            background-color: #11673b;
          }
        }
      }

      .exit {
        cursor: pointer;
      }
    }
  }

  .search-bar {
    background-color: #ffffff;
    color: #000;
    padding: 3px;
    /* padding: 6px; */
    border-bottom-color: #e1e3f1;
    border-bottom-style: solid;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding-right: 1em;
    width: 100%;

    .summary-info {
      font-size: 14px;
      padding-right: 1em;
    }

    .actions {
      display: flex;

      div {
        padding: 3px 22px;
        margin-right: -2px;
        /* background-color: #bfbfbf47; */
        text-align: center;
        color: #16166d;
        border-radius: -1;
        border-style: solid;
        border-color: #2d05ff;
        border-width: 2px;
        font-size: 14px;
        cursor: pointer;
        color: #2d05ff;
        border-radius: 22px;
        margin-right: 8px;

        &:hover {
          background-color: #2d05ff;
          color: #fff;
        }
      }
    }
  }

  .list-view {
    .table-row {
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      flex-grow: 0;
      -webkit-flex-grow: 0;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      background-color: #fff;
      border-bottom-color: #d2d2d2;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      padding: 8px 0;
      cursor: pointer;
      font-size: 13px;
      color: #545454;

      &.highlighted {
        background-color: #a6e93cc7;
      }
    }

    // Text based cells: grow, truncate and show ellipsis when there is not enough space
    .text {
      flex-grow: 1;
      -webkit-flex-grow: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 20px;
    }

    .radio-box {
      width: 5%;
      text-align: center;
    }

    .name {
      font-weight: 600;
      width: 25%;
      text-overflow: ellipsis;
      padding-left: 1em;
    }

    .type {
      width: 15%;
    }

    .screens {
      width: 15%;
    }

    .area {
      width: 20%;
    }

    .more-info {
      width: 10%;
      text-align: center;
    }
  }

  .search-filters {
    label {
      cursor: pointer;
    }
  }

  .map-container {
    flex-direction: column;
    height: 100%;
    width: 100%;

    .map-view {
      flex-grow: 1;
      width: 100%;
      height: 85vh;
    }
  }

  .input-range__slider {
    height: 10px;
    margin-left: -4px;
    top: 64%;
    width: 10px;
    margin-top: -8px;
  }
}

.condo-filter {
  label.checkbox-style {
    font-weight: bolder;
  }
}

.disabled {
  color: #a1a1a1 !important;
  pointer-events: none !important;

  p {
    color: #a1a1a1 !important;
  }
}

.main-filter {
  h5 {
    font-size: 15px;
    padding-top: 5px;
    margin-left: -4px;
    padding-bottom: 2px;
    font-weight: bolder;
  }

  hr {
    margin: 2em 0 1em;
  }

  p {
    padding: 5px 15px 2px 0em;
    font-size: 13px;
    margin-bottom: 15px;
    color: #252526;
    margin-left: -4px;
    font-weight: bolder;
  }

  label.checkbox-style {
    font-weight: bolder;
  }

  label.filter-section {
    font-weight: bolder;
    font-size: 15px;
    margin-top: 8px;
    margin-left: -4px;
  }

  .filter-head {
    font-size: 18px;
    font-weight: bolder;
    padding-top: 5px;
  }
}

.rental-filter {
  label.checkbox-style {
    font-weight: bolder;
  }
}

/******
MARKERS
******/

.chosen-marker {
  //background-image: url("../images/selected_sv.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding: 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1, 1.1);
  }

  /* &:hover {
transform: scale(1.1, 1.1);
}
.selected {
transform: scale(1.1, 1.1);
}
*/
}

.small-textarea {
  width: 10em !important;
  height: 2em;
}

.not-chosen-marker {
  // background-image: url("../images/unselected_sv.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding: 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  /*
&:hover {
transform: scale(1.1, 1.1);
}
.selected {
transform: scale(1.1, 1.1);
}
*/
}
