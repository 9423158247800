.tablet_wrapper {
  background: #000000;
  border: 3px solid $color-vblack;
  border-radius: 3em;
  padding: 2em 2em 2em;
  position: relative;
  width: 1368px;
  height: 768px;
  transform-origin: 0 0;
  max-width: 10000px;

  &__content {
    border: 2px solid $color-vblack;
    overflow: hidden;
    border-radius: 0.5em;
    max-width: 10000px;

    &--property-logo-holder {
      background-image: url(../images/logo_holder.png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 180px;
    }
  }

  &__visio_logo {
    // this is a hack to preserve scaling
    width: initial;
    height: 52px;
    padding-left: 0.5em;
  }
}

