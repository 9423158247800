.mainTable {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.025em;

  &__header {
    color: getColor(primary-blue, lightest);
  }

  &__body {
    color: getColor(primary-blue, base);
    font-size: 13px;
  }

  &__withBorder {
    table {
      border: 1px solid #c6d2e1 !important;
    }
  }

  table {
    background-color: $color-vwhite;
    border: none;
    box-sizing: border-box;
    margin-bottom: 0;
    th,
    td {
      border-bottom: 2.5px solid tint(getColor(primary-blue, base), 95);
      border-top: none;
      border-left: none;
      border-right: none;
      padding: 0;
    }
    th:not(:first-child),
    td:not(:first-child) {
      text-align: center;
      .col {
        justify-content: center;
      }
    }
    td {
      border-bottom-width: 0.25px;
      vertical-align: middle;
      padding: 1em;
    }
  }
}
