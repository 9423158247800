.topBar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1em;
  justify-content: space-between;
  gap: 0.5em;
  margin-bottom: 0px;

  &__section {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;

    .pill {
      display: flex;
      align-items: center;
      font-size: 1em;
    }
  }

  &__title {
    font-family: "Gilroy-Bold";
    color: getColor(primary-blue);
    font-size: 1.5em;
  }
}
