.login {
  &__image-section {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-flow: row wrap;
    background-color: #143258;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/p3.jpg);
  }

  &__description {
    color: #fff;
    font-size: 2.5em;
    font-family: "Gilroy-Bold";
  }

  &__logos {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    max-width: 45%;
  }

  .tosDescription {
    font-size: 0.7em;
    a {
      font-weight: bold;
    }
  }

  .forgot-password {
    text-align: center;
    padding-top: 1em;
    color: #317ed2;
    cursor: pointer;
    font-size: 1em;
  }

  hr {
    border: 1px solid white;
    flex: 1 100%;
  }
}

//media quiery

@media (min-width: $mq-small) {
  .login {
    margin-top: 10%;
  }
}
