.table-header {
  padding: 0.5em 1em;
  background-color: #fff;
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 8;
  position: sticky;
  margin-bottom: 5px;
  position: -webkit-sticky;
  top: 0;
  &__search-bar {
    display: flex;
    align-items: center;
    i.fa.fa-search {
      font-size: 21px;
      color: #979797;
    }
    input[type="text"] {
      width: 100%;
      border-style: none none solid none;
      border-color: #979797;
      border-width: 1px;
      padding-left: 0.5em;
    }
  }
}

@media (max-width: $mq-small) {
  .table-header {
    &__title {
      font-size: 1.3em;
    }
    &__search-bar {
      padding-top: 0.2em;
      i.fa.fa-search {
        font-size: 1.7em;
      }
      input[type="text"] {
        font-size: 1.7em;
      }
    }
  }
}
