//Constants
$lighten: 10;
$darken: 10;

// TODO: Deprecate in favor of tint-color
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage * 1%);
}

// TODO: Deprecate in favor of shade-color
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage * 1%);
}

@function getColor($base, $shade: base) {
  $color: map-get(map-get($vc-colors, $base), $shade);
  @return $color;
}

$_primary-blue: #133256;
$_orange: #f04d28;
$_light-blue: #97c7e2;
$_turquoise: #0eccc2;
$_magenta: #ea4968;
$_purple: #6442c5;
$_dark_yellow: #fdc457;

//Brand Colors

$vc-colors: (
  primary-blue: (
    base: $_primary-blue,
    light: tint($_primary-blue, $lighten),
    lighter: tint($_primary-blue, $lighten * 3),
    lightest: tint($_primary-blue, $lighten * 5),
    dark: shade($_primary-blue, $darken),
    darker: shade($_primary-blue, $darken * 2),
    darkest: shade($_primary-blue, $darken * 3),
  ),
  orange: (
    base: $_orange,
    light: tint($_orange, $lighten),
    lighter: tint($_orange, $lighten * 3),
    lightest: tint($_orange, $lighten * 5),
    dark: shade($_orange, $darken),
    darker: shade($_orange, $darken * 2),
    darkest: shade($_orange, $darken * 3),
  ),
  turquoise: (
    base: $_turquoise,
    light: tint($_turquoise, $lighten),
    lighter: tint($_turquoise, $lighten * 7),
    lightest: tint($_turquoise, $lighten * 8.7),
  ),
  magenta: (
    base: $_magenta,
  ),
  purple: (
    base: $_purple,
  ),
  dark_yellow: (
    base: $_dark_yellow,
  ),
);

$color-vwhite: #ffffff;
$color-green-brand: #bad333;

$color-vblack: #252424;

$color-blue-light: #34cad8;
$color-green-light: #72cd10;

$color-vgrey: #94959a;

$color-vgreen: #bad332;
$color-vgreen-darker: darken($color-vgreen, 10%);
$color-vgreen-darkest: darken($color-vgreen, 25%);

$color-vorange: #d27a00;
$color-vorange-lighter: lighten($color-vorange, 10%);

:export {
  primaryBlue: $_primary-blue;
  orange: $_orange;
  lightBlue: $_light-blue;
  turquoise: $_turquoise;
  magenta: $_magenta;
  purple: $_purple;
  darkYellow: $_dark_yellow;
}
