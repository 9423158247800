//d-flex flex-column align-items-center

.simple-image-uploader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: auto;
  padding: 0.5em;

  background-color: #fbfdff;
  color: #3d4864;
  border-style: dashed;
  border-color: #7495b4;
  border-radius: 5px;
  border-width: 1.5px;

  cursor: pointer;

  &--rejectFile {
    border-color: red;
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -0.75rem;
    margin-right: -0.75rem;
    font-size: 1.5rem;
    border-radius: 0.75rem;
    border-color: #7495b4;
    background-color: #fbfdff;
    color: #7495b4;
  }
}

.simple-image-delete {
  @extend .simple-image-uploader;

  &__icon {
    @extend .simple-image-uploader__icon;
    position: relative;
    margin-top: -1rem;
    margin-right: 0;
    margin-left: -1rem;
  }
}
