.reporting-progress-bar {
  &__container {
    position: relative;
    display: flex;
    overflow: hidden;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    background-color: tint(getColor(primary-blue, lightest), 40);
    height: 1.2rem;
    font-size: 0.75rem;
  }
  &__bar {
    background-color: getColor(purple, base);
    height: inherit;
  }
  &__description {
    color: #fff;
    justify-content: space-between;
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    padding: 0 0.5rem;
    align-items: center;
    height: inherit;
    font-family: "FiraSans-Regular";
  }
}
